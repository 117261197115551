.profile {
  background-color: rgba(59,86,152,.5);
  // background-color: linear-gradient(to right, rgba(59,86,152,.3), rgba(59,86,152,.3));
  color: white;
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  width: 100%;
  min-width: 480px;
  max-width: 960px;
  z-index: -1;

  .profile-title {
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;

    .black {
      color: black;
    }

    .orange {
      color: orange;
    }
  }

  .pfp-img {
    border: 10px solid #4c70ba;
    border-radius: 100%;
    height: 350px;
    width: 350px;
  }

  .footer {
    margin-top: 15px;
    margin-bottom: 10px;

    a {
      margin: 0 3px 0 3px;
      padding: 3px;
    
      border-width: 1px;
      border-style: solid;

      &:visited {
        color: inherit;
      }

      &:hover {
        background-color: #fff;
        color: #3B5998;
        transition: 0.5s;
      }
    }
  }
}

// .profile > .footer {
//   margin-top: 15px;
//   margin-bottom: 10px;

// }

// .profile > .footer > a {
//   margin: 0 3px 0 3px;
//   padding: 3px;

//   border-width: 1px;
//   border-style: solid;
// }

.profile > .footer > a:visited {
  color: inherit;
}

.profile > .footer > a:hover {
  background-color: #fff;
  color: #3B5998;
  transition: 0.5s;
}