.exp-block {
  line-height: normal;

  h6 {
    border-bottom: 1px solid #3B5998;

    svg {
      vertical-align: baseline;
    }
  }

  .date-span {
    float: right;

    svg {
      color: #3B5998;
    }
  }
}