

.resume {
  // padding: 20px !important;

  // ul li {
  //   margin: 5px 15px 5px 15px;
  //   list-style: disc;
  //   list-style-position: initial;
  //   list-style-image: initial;
  //   list-style-type: disc;
  // }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}