.portfolio-filter-group {
  margin-left: 10px;

  button {
    border-radius: unset;
    border-color: #3B5998;
    margin: 5px;
    background: #577285;

    // .btn.active {
    //   background: red !important;
    // }
  }
}