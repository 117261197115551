$line-break-gray: #e6e6e6;

.profile-wrapper {
  // h2 {
  //   border-bottom: 1px solid $line-break-gray;
  // }

  // .about{

  // }


  // .services {

  // }

  .service-container {
    margin-top: 20px;

    // div {
    //   margin: 10px 0 10px 0;
    // }
  }
}

