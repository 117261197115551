$primary-blue: #3B5998;
$line-break-gray: #e6e6e6;

/* body {
  min-height: 480px;
  max-height: 2400px;
} */

body {
  background-image: url('./common/images/bg5.png');
  padding: 10px;
  margin: -10px;
  // overflow: hidden;
}

#root {
  height: 100%;
}

// html, body, #root, #root>div {
//   height: 110%;
//   background-image: url('./common/images/bg5.png');
//   /* overflow: auto; */
//   /* min-height: 480px;
//   max-height: 2400px; */
//   /* overflow-y: scroll; */
// }



.App {
  // background-image: url('./common/images/bg5.png');
  /* background-size: auto 100%; */
  /* height: 100%; */
  /* overflow: auto; */

  .top-container {
    // margin-left: 5%;
    // margin-right: 5%;
    margin: auto;
    // position: absolute;
    // top: 50%;
    // height: 90%;
    // min-height: 480px;
    width: 90%;
    min-width: 480px;
    max-width: 960px;
    padding-top: 10px;
    position: relative;
    // background: #f4f4f4;
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      font-family: 'Lato', sans-serif;
      font-weight: 300;
    }

    h1, h2, h6 {
      color: $primary-blue;
    }

    h1 {
      font-size: 26px;
      background: url('./common/images/arrows-h3/arrow-DarkBlue-h3.png') no-repeat 0px 3px;
      text-transform: inherit;
      margin-top: 0;
      margin-bottom: 15px;
      padding-bottom: 10px;
      padding-left: 30px;
      border-bottom: 1px solid $line-break-gray;
    }

    h2 {
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 0;
      border-bottom: 1px solid $line-break-gray;
    }

    h6 {
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 10px;
      /* color: rgba(59,86,152,1); */
    }

    p {
      font-size: 16px;
    }

    .h2-wrapper {
      &:after {
        height: 1px;
        display: block;
        left: 0;
        content: " ";
        position: relative;
        width: 30px;
        bottom: 1px;
        background: $primary-blue;
        // margin-bottom: 5px;
      }
    }

    svg {
      vertical-align: text-top;
    }

    .page-container {
      background-color: #f4f4f4;
      padding: 30px;
      /* display: none; */

      /* overflow-y: scroll; */

      /* height: 100%; */
    }
  }
}

* {
  margin: 0;
  padding: 0;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.dropdown-toggle::after {
  display: none !important;
}